import React, { Component } from "react"
import { Col, Container, Row } from "react-bootstrap"
import shop2 from "../images/shops/cociekawe logo 2020.svg"

class Shop extends Component {
  render() {
    return (
      <section id="shop">
        <Container>
          <Row>
            <Col>
              <h3>Zobacz dostępne wzory
                <br />
              i kup maskę antysmogową Vaire u autoryzowanego sprzedawcy!</h3>
              <div>
                <a href="https://cociekawe.pl/kategoria/maski-antysmogowe/" target="_blank">
                  <figure>
                    <img src={shop2} alt="Sklep Co ciekawe" />
                    <figcaption>Sklep internetowy <br /> Zdrowie | Biohacking | Zero-waste</figcaption>
                  </figure>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Shop