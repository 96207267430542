import React, { Component } from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./accessories.scss"
import { Parallax } from "react-scroll-parallax"

import bag from "../images/accessories/bag.svg"
import filter from "../images/accessories/filter.svg"
import dust from "../images/accessories/dustIcon.svg"
import percent from "../images/accessories/percent.svg"
import time from "../images/accessories/time.svg"
import humidity from "../images/accessories/humidityIcon.svg"
import icon from "../images/accessories/filterIcon.svg"
import organized from "../images/accessories/organized.svg"
import desinfection from "../images/accessories/desinfection.svg"

import filterLayers from "../images/accessories/filterLayers.svg"


class Accessories extends Component {
  render() {
    return (
      <section id="accessories">
        <Container>
          <Row>
            <Col md={12}>
              <h3>Aksamitne etui Vaire</h3>
            </Col>
            <Col md={6} className="margin-bottom-90">
              <p>Wiemy, że <strong>przechowywanie maski przeciwpyłowej jest bardzo ważne.</strong><br />
                Do każdej kupionej maski dołączamy aksamitne etui. Woreczek jest przyjemny w dotyku.
                Zapewnia higienę. Będzie eleganckim i praktycznym opakowaniem na prezent.</p>
              <ul className="icon-list">
                <li>
                  <img src={dust} alt="Kurz i zabrudzenia" />
                  <p>jest <b>barierą</b> przed <b>kurzem i zabrudzeniami</b></p>
                </li>
                <li>
                  <img src={humidity} alt="Wilgoć" />
                  <p><b>zabezpiecza</b> przed wilgocią i <b>rozwojem drobnoustrojów</b></p>
                </li>
                <li>
                  <img src={organized} alt="Organizer" />
                  <p>zapewnia <b>porządek</b> i jedno miejsce dla filtrów, maski antysmogowej Vaire i żelu antybateryjnego</p>
                </li>
              </ul>
            </Col>
            <Col md={6} className="bag-image-container">
              <Parallax className="custom-class" y={[0, -100]} tagOuter="figure">
                <img src={filter} alt="PM2.5 Filtr do maski antysmogowej" />
              </Parallax>

              <img src={bag} alt="Etui na maskę przeciwsmogową" className="bag-image" />
            </Col>
          </Row>
          <Row>
            <Col md={12}><h3> Węglowy filtr Vaire (N95, PM2.5)</h3></Col>
            <Col md={12}>
              <p>Maska od wewnętrznej strony ma wszytą bawełnianą kieszonkę. Do kieszonki można wsunąć wymienny filtr węglowy.
                Filtry pasują do każdej maski Vaire Casual. W zestawie otrzymujesz <b>dwa filtry.</b></p>
              <ul className="icon-list">
                <li>
                  <img src={percent} alt="95 procent filtrowanych zanieczyszczeń w maskach antysmogowych Vaire" />
                  <p>zapewnia ponad 95% skuteczności w zatrzymywaniu zawieszonego pyłu PM2.5 i PM10</p>
                </li>
                <li>
                  <img src={time} alt="Czas używania filtrów w maskach przeciwsmogowych Vaire" />
                  <p>wystarcza na użytkowanie nawet przez dwa tygodnie lub do 40 godzin noszenia, jednak zalecamy wymianę po tygodniu lub 20 godzinach używania</p>
                </li>
                <li>
                  <img src={desinfection} alt="Jak dezynfekować filtry do masek antysmogowych" />
                  <p>zaleca się dezynfekcję maski oraz filtrów po każdym użyciu
                  <br/><small>można to zrobić używając z obu stron środka do dezynfekcji bezpiecznego dla dróg oddechowych,
                  <br/>maskę bez filtrów zdezynfekuj poprzez prasowanie</small>
                  </p>
                </li>
              </ul>
            </Col>
            <Col md={6}>
              <h4>Pięciowarstwowy</h4>
              <ul className="list-unstyled">
                <li>warstwa oddychająca – mechanicznie odfiltrowuje cząsteczki większe niż 10 mikrometrów</li>
                <li>gęsta tkanina filtrująca – stanowi pierwszą barierę dla cząsteczek PM2,5</li>
                <li>węgiel aktywny – neutralizuje szkodliwe związki</li>
                <li>gęsta tkanina filtrująca – stanowi drugą barierę dla cząsteczek PM2,5</li>
                <li>warstwa antystatyczna – zapobiega przywieraniu brudu</li>
              </ul>
            </Col>
            <Col md={6} className="m-auto">
              <img src={filterLayers} alt="Warstwy filtra do maski przeciwsmogowej" />
            </Col>
            <Col md={6}>
              <h4>Usuwa niebezpieczne związki takie jak:</h4>
              <ul>
                <li>Spaliny samochodowe</li>
                <li>Pył węglowy</li>
                <li>Pyłki roślin</li>
                <li>Kurz</li>
                <li>Drobnoustroje</li>
                <li>Pleśń</li>
                <li>Dwutlenek siarki (SO2)</li>
                <li>I ponad 150 innych substancji szkodliwych dla człowieka</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Accessories