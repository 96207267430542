import React, { Component } from "react"
import { Col, Form } from "react-bootstrap"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { message } from "antd"

class ContactForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: "",
      name: "",
    }
  }

  _handleSubmit = async (e) => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email, { FNAME: this.state.name })
    if (result.result === "success") {
      message.success(result.msg.split("<")[0])
    } else {
      message.error(result.msg.split("<")[0])
    }
    this.props.disableModal()
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  render() {
    return (
      <Form onSubmit={this._handleSubmit}>
        <h4>Newsletter Vaire</h4>
        <p>Jeśli chcesz dowiedzieć się o dostępności nowych wzorów jako pierwszy
           i skorzystać z indywidualnych akcji promocyjnych to <strong>zapisz się</strong> poniżej!</p>
        <Form.Row>
          <Form.Group as={Col} md={6} controlId="formBasicName">
            <Form.Control required type="text" placeholder="Imię" onChange={this.handleNameChange} />
            <Form.Control.Feedback>Wygląda dobrze!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={6} controlId="formBasicEmail">
            <Form.Control required type="email" placeholder="Email" onChange={this.handleEmailChange} />
            <Form.Text className="text-muted">
              Nigdy nie udostępnimy Twojego maila osobom trzecim.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check required type="checkbox" label="Zgadzam się na przesyłanie ofert." />
          </Form.Group>
        </Form.Row>
        <button type="submit">
          Zapisuję się
        </button>
      </Form>
    )
  }
}

export default ContactForm