import React from "react"
import { Modal } from "react-bootstrap"
import Form from "./form"

function ModalWindow(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Form disableModal={props.onHide} modal={true}/>
      </Modal.Body>
    </Modal>
  )
}

export default ModalWindow