import React, { Component } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { message } from "antd"

class Signup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      name: "",
    }
  }

  _handleSubmit = async (e) => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email, { FNAME: this.state.name })
    if (result.result === "success") {
      message.success('Dziękujemy, zostałeś zapisany.')
      // message.success(result.msg.split("<")[0])
    } else {
      message.error('Zapis nie powiódł się, spróbuj ponownie.')
      // message.error(result.msg.split("<")[0])
    }
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  handleNameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  render() {
    return (
      <section id="signup">
        <Container>
          <Row>
            <Col className="m-auto" md={8}>
              <Form onSubmit={this._handleSubmit}>
                <h4>Newsletter Vaire</h4>
                <p>Jeśli chcesz dowiedzieć się o dostępności nowych wzorów jako pierwszy
                i skorzystać z indywidualnych akcji promocyjnych to <strong>zapisz się</strong> poniżej!</p>
                <Form.Row>
                  <Form.Group as={Col} md={6} controlId="formBasicName">
                    <Form.Control required type="text" placeholder="Imię" onChange={this.handleNameChange} />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={6} controlId="formBasicEmail">
                    <Form.Control required type="email" placeholder="Email" onChange={this.handleEmailChange} />
                    <Form.Text className="text-muted">
                      Nigdy nie udostępnimy Twojego maila osobom trzecim.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox2">
                    <Form.Check required type="checkbox" label="Wyrażam zgodę na przesyłanie ofert marketingowych na podany adres email." />
                  </Form.Group>
                </Form.Row>
                <button type="submit">
                  Zapisuję się!
                </button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Signup