import React from "react"
import "./index.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Features from "../components/features"
import Signup from "../components/signup"
import Shop from "../components/shop"
import Reviews from "../components/reviews"
import ModalWindow from "../components/modal"
import "bootstrap/dist/css/bootstrap.min.css"
import { ParallaxProvider } from "react-scroll-parallax"
import Accessories from "../components/accessories"

// https://strapi.io/blog/building-a-static-website-using-gatsby-and-strapi
// TODO: https://www.gatsbyjs.com/plugins/gatsby-source-instagram/ 

const IndexPage = ({ data }) => {
  const [modalShow, setModalShow] = React.useState(false)
  const activateModal = () => {
    setModalShow(true)
  }
  return (
    <Layout activateModal={activateModal} > 
      <SEO title="Vaire - Maski antysmogowe i maski ochronne" meta="" />
      <Banner activateModal={activateModal} />
      <Features />
      <ParallaxProvider>
        <Accessories />
      </ParallaxProvider>
      <div id="videoWithNoJs" className="videoWrapper">
        <iframe src="https://www.youtube.com/embed/1q8kZNiPHto?list=PLl4wpV9sFMskDc2AP-p2ZDplw2kHFFeD6&showinfo=0&controls=0&rel=0&modestbranding=1&autohide=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <Signup activateModal={activateModal} />
      <Shop />
      <Reviews />
      <ModalWindow show={modalShow} onHide={() => setModalShow(false)} />
    </Layout>
  )
}

export default IndexPage
