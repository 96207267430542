import React, { Component } from "react"
import "./banner.scss"
import { Container, Row, Col } from "react-bootstrap"

class Banner extends Component {
  render() {
    return (
      <section className="banner">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <Container>
          <Row>
            <Col md={5} className="text-container">
              <h1>Ochronne maski antysmogowe dla Ciebie</h1>
                <p><b>Vaire</b> to sportowe i codzienne maski antysmogowe wielokrotnego użytku.
                <br />Sprawdzą się w ochronie <b>przed smogiem i w profilaktyce</b> zdrowotnej.</p>
              <div className="styled-button" onClick={this.props.activateModal}>Chcę poznać ofertę
                specjalną &nbsp; &#10095;</div>
            </Col>
            <Col md={7} className="image-container round">
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Banner