import React, { Component } from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./reviews.scss"
import { FaStar, FaCheckCircle } from 'react-icons/fa';

class Reviews extends Component {
  render() {
    return (
      <section id="reviews">
        <Container>
          <Row>
            <Col md={12} className="text-container">
              <h2>Opinie</h2>
              <h3>Zobacz kilka najbardziej pomocnych opinii o maskach Vaire Casual</h3>
              <p>Opinie pochodzą ze sklepu cociekawe.pl</p>
            </Col>
          </Row>

          <Row>
            <Col md={5} xs={12} className="review">
              <div className="circle small"></div>
              <div className="circle big"></div>
              <strong>Maska antysmogowa Vaire Lazarus Black</strong>
              <span className="verified-purchase float-right">
                <FaCheckCircle color="green"></FaCheckCircle>
                Opinia potwierdzona zakupem</span>
              <small className="block">19 kwietnia 2020 dodała <em>Paulina Adamczak-Kaczmarek (zweryfikowana)</em></small>
              <span>
                <FaStar color="orange" />
                <FaStar color="orange" />
                <FaStar color="orange" />
                <FaStar color="orange" />
                <FaStar color="orange" />
              </span>
              <p>Cudne! Wygodne. Można w niej spokojnie oddychać. Nie przeszkadza.</p>
            </Col>

            <Col md={5} xs={12} className="review">
              <strong>Maska antysmogowa Vaire Spring Breath</strong>
              <span className="verified-purchase float-right">
                <FaCheckCircle color="green"></FaCheckCircle>
                Opinia potwierdzona zakupem</span>
              <small className="block">24 kwietnia 2020 dodała <em>wiolaw1972 (zweryfikowana)</em></small>
              <span>
                <FaStar color="orange" />
                <FaStar color="orange" />
                <FaStar color="orange" />
                <FaStar color="orange" />
                <FaStar color="orange" />
              </span>
              <p>Delikatny materiał nie podrażnia twarzy… robi wrażenie wśród innych kobiet 😊 polecam 👍</p>
            </Col>

            <Col md={5} xs={12} className="review">
              <strong>Maska antysmogowa Vaire Lazarus Black</strong>
              <span className="verified-purchase float-right">
                <FaCheckCircle color="green"></FaCheckCircle>
                Opinia potwierdzona zakupem</span>
              <small className="block">9 grudnia 2019 dodała <em>Karolina Czech (zweryfikowana)</em></small>
              <span>
                <FaStar color="orange" />
                <FaStar color="orange" />
                <FaStar color="orange" />
                <FaStar color="orange" />
                <FaStar color="orange" />
              </span>
              <p>Z czystym sumieniem polecam produkt. Wszystko zgodne z opisem, doskonała jakość za niewielką cenę i świetna obsługa 😉</p>
            </Col>

            <Col md={5} xs={12} className="review">
              <strong>Maska antysmogowa Vaire Spring Breath</strong>
              <span className="verified-purchase float-right">
                <FaCheckCircle color="green"></FaCheckCircle>
                Opinia potwierdzona zakupem</span>
              <small className="block">25 kwietnia 2020 dodała <em>MagaP (zweryfikowany)</em></small>
              <span>
                <FaStar color="orange" />
                <FaStar color="orange" />
                <FaStar color="orange" />
                <FaStar color="orange" />
                <FaStar color="orange" />
              </span>
              <p>Bardzo wygodna, regulacja gumek, delikatny materiał… Fajny wzór, dobrze się oddycha. Polecam!</p>
            </Col>
          </Row>
        </Container>
      </section >
    )
  }
}

export default Reviews