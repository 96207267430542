import React, { Component } from "react"
import { Col } from "react-bootstrap"
import "./products.scss"
import OverlayImage from "../images/products/inline/blossom-overlay.svg"

class MaskAnimation extends Component {
  render() {
    return (
      <Col md={8} className="m-auto">
        <OverlayImage className="mask" />
      </Col>
    )
  }
}

export default MaskAnimation