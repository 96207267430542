import React, { Component } from "react"
import { Col, Container, Row } from "react-bootstrap"
import allergies from "../images/features/allergies-grad.svg"
import elastic from "../images/features/elastic1.svg"
import natural from "../images/features/natural.svg"
import regulation from "../images/features/regulation.svg"
import style from "../images/features/style.svg"
import unisex2 from "../images/features/unisex2.svg"
import MaskAnimation from "./maskAnimation"
import './features.scss';

class Features extends Component {
  render() {
    return (
      <section id="features">
        <Container>
        <h2>Dlaczego warto wybrać Vaire?</h2>
        <h3>6 cech wyróżniających maski antysmogowe Vaire</h3>
        <p>Dostarczamy najwyższej jakości filtry z <b>ochroną N95</b>, które <b>chronią przed pyłami PM10 oraz PM2.5</b>. nasze maski antysmogowe dzięki unikalnym wzorom dodają odświeżenia do każdego miejsca. 
Dodatkowo zwracamy uwagę na wygodę. Elastyczny nosek oraz dopasowane gumki świetnie leżą na każdym typie twarzy. Do tego wszystkiego dostaniesz zajedwabisty woreczek 😏
</p>
          <Row className="justify-content-md-center">
              <div className="feature">
                <img className="feature-image" src={elastic} alt="Elastyczność" />
                <div className="text-container">
                  <p className="title">Elastyczny nosek</p>
                  <p className="description">Maska antysmogowa <b>dopasowuje się</b> do każdego kształtu <b>nosa</b>,
dzięki elastycznemu klipowi wszytemu między materiał.
Dla zapewnienia najlepszej <b>skuteczności</b>.</p>
                </div>
              </div>
              <div className="feature">
                <img className="feature-image" src={allergies} alt="Alergie" />
                <div className="text-container">
                  <p className="title">Ochrona</p>
                  <p className="description">
Bawełniana powierzchnia w połączeniu z filtrami zapewnia <b>ochronę</b> przed <b>smogiem, drobnoustrojami i alergenami.</b>
                </p>
                </div>
              </div>
              <div className="feature">
                <img className="feature-image" src={unisex2} alt="Unisex" />
                <div className="text-container">
                  <p className="title">Uniwersalność</p>
                  <p className="description">
                   Jeden rozmiar pasujący dla <b>wszystkich</b> dorosłych, aby ułatwić Ci wybór. 
                   Odpowiednia zarówno dla <b>kobiet i mężczyzn</b>.
                  </p>
                </div>
              </div>
              </Row>
          <Row>
              <div className="feature">
                <img className="feature-image" src={style} alt="Styl" />
                <div className="text-container">
                  <p className="title">Styl i jakość</p>
                  <p className="description">
                  Maska antysmogowa stworzona z materiałów <b>wysokiej</b> jakości. Wybierz dla siebie <b>stonowany lub ekstrawagancki</b> styl. 
                  </p>
                </div>
              </div>
              <div className="feature">                  
                <img className="feature-image" src={regulation} alt="Regulacja" />
                <div className="text-container">
                  <p className="title">Regulacja</p>
                  <p className="description">
                  Dopasowanie maski przeciwpyłowej jest <b>najważniejsze</b> dla jej <b>skuteczności</b>.
                  Każda maska na elastycznych gumeczkach ma <b>silikonowy klip</b>,
                  który pozwala w sekundę <b>dopasować</b> długość gumek. 
                  Gumki wykonane są z <b>delikatnego</b> materiału, tak aby nie podrażniać uszu.
                  </p>
                </div>
              </div>
              <div className="feature">
                <img className="feature-image" src={natural} alt="Naturalne materiały" />
                <div className="text-container">
                  <p className="title">Naturalne materiały</p>
                  <p className="description">
                  Wewnętrzna część maski antysmogowej jest wyścielona białą <b>bawełnianą tkaniną</b>.  
                  Nie podrażnia twarzy i zapobiega potliwości.
                  </p>
                </div>
              </div>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <MaskAnimation />
          </Row>
        </Container>

      </section>
    )
  }
}

export default Features